import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../Images/Lightequi.png";
import pin from "../Images/pin.png";
import tel from "../Images/call.png";
import gmail from "../Images/gmail.png";
import whatsapp from "../Images/whatsapp.png"

function Footer() {
  return (
    <div className="footer-container">
      <footer className="footer">
        <div className="item">
          <img src={logo} alt="" />
        </div>
        <div className="item ">
          <h2>About Us</h2>
          <Link to="/">Home</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/contact-us">Contact Us</Link>
        </div>

        <div className="item w15">
          <h2>Quick Links</h2>
          <div className="it">

            <div className="it1">
              <Link to="/saddles/western-saddles">Western Saddles</Link>
              <Link to="/saddles/australian-saddles">Autralian Saddles</Link>
              <Link to="/saddles/racing-saddles">Racing Saddles</Link>
              <Link to="/saddles/saddle-pads">Saddle Pads</Link>
              <Link to="/saddles/all-purpose-saddles">All Purpose Saddles</Link>
            </div>

            <div className="it2">
              <Link to="/briddles">Briddles/Head Stall</Link>
              <Link to="/rugs">Horse Rugs</Link>
              <Link to="/horseboot">Horse Boots</Link>
              <Link to="/harness-sets">Harness Sets</Link>
              <Link to="/accessories">Accessories</Link>
            </div>

          </div>


        </div>


        <div className="item">
          <h2>Contact Us</h2>
          <div className="footer-img">
            <img src={pin} alt="" />
            <p>
              2 D - 319 , Awas Vikas , Hanspuram, Naubasta, Kanpur -208021 ,
              India
            </p>
          </div>
          <div className="footer-img">
            <img src={gmail} alt="" />
            <a href="mailto:lightequestrianinc@gmail.com">lightequestrianinc@gmail.com</a>
          </div>
          <div className="footer-img">
            <img src={gmail} alt="" />
            <a href="mailto:contactus@lightequestrianinc.com">contactus@lightequestrianinc.com</a>
          </div>
          <div className="footer-img">
            <img src={tel} alt="" />
            <a href="tel:+919336338576">+91 9336338576</a>
          </div>
          <div className="footer-img">
            <img src={whatsapp} alt="" />
            <a href="tel:+918726275619">+91 8726275619</a>
          </div>
        </div>
      </footer>

      <div className="copyright">
        © LIGHT EQUESTRIAN INC. INDIA. 2022.All rights reserved.
      </div>
    </div>
  );
}
export default Footer;
