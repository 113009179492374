import React from "react";
import WesternData from "../Saddle/SubSaddles/WesternData";
import ImageCard from "../../Templates/ImageCard";
function Accessories() {
  return (
    <section>
      <h1>Accessories</h1>
      <div className="western_container">
        {WesternData.Accessories.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default Accessories;
