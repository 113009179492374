import "./Navbar.css";
import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../Images/Lightequi.png";

function Navbar() {
  const [clicked, setClicked] = useState(false);
  const [isMobile1, setIsMobile1] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <>
      <nav>
        <div className="logo">
          <NavLink exact to="/">
            <img src={logo} alt="Logo" />
          </NavLink>
        </div>

        <ul
          className={clicked ? "menu-list" : "menu-list close"}
        // onClick={() => setClicked(false)}
        >
          <div className="features">
            <li onClick={() => setClicked(false)}>
              {" "}
              <NavLink exact to="/">
                HOME
              </NavLink>{" "}
            </li>
            <li onClick={() => setClicked(false)}>
              {" "}
              <NavLink exact to="/about-us">
                ABOUT US
              </NavLink>{" "}
            </li>

            <li className={!isMobile1 ? "list" : "list1"} onClick={() => setIsMobile1(!isMobile1)}>
              PRODUCTS <i class="fas fa-angle-down"></i>
              <ul className={!isMobile1 ? "dropdown-menu" : "none"}>
                <li onClick={() => setClicked(false)}>
                  {" "}
                  <Link to="/saddles">Saddles</Link>{" "}
                </li>
                <li onClick={() => setClicked(false)}>
                  <Link to="/briddles">Bridles/Head Stall</Link>{" "}
                </li>
                <li onClick={() => setClicked(false)}>
                  <Link to="/halters"> Halters</Link>
                </li>
                <li onClick={() => setClicked(false)}>
                  <Link to="/rugs">Horse Rugs</Link>{" "}
                </li>
                {/* <li>
                  <Link to="/sdalepads">Sadle Pads</Link>{" "}
                </li> */}
                <li onClick={() => setClicked(false)}>
                  <Link to="/horseboot">Horse Boots</Link>{" "}
                </li>
                <li onClick={() => setClicked(false)}>
                  <Link to="/harness-sets">Harness Sets</Link>{" "}
                </li>
                <li onClick={() => setClicked(false)}>
                  <Link to="/accessories">Accessories</Link>{" "}
                </li>
                <li onClick={() => setClicked(false)}>
                  <Link to="/new-category">New Arrivals</Link>{" "}
                </li>
              </ul>
            </li>

            <li onClick={() => setClicked(false)}>
              {" "}
              <NavLink exact to="/contact-us">
                CONTACT US
              </NavLink>{" "}
            </li>
          </div>
        </ul>

        <div className="menu-icon" onClick={handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
