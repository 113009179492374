import React from "react";
import { Link } from "react-router-dom";
import "./ContactUs.css";
import pin from "../Images/Icon material-location-on.png";
import email from "../Images/Icon material-email.png";
import call from "../Images/Icon ionic-ios-call.png";
import wa from "../Images/whatsapp (2).png";
import { useRef } from "react";
import emailjs from '@emailjs/browser';

function ContactUs() {
  // console.log(key);
  const form = useRef()


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        alert("Message sent successfully")
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset()
  };




  return (
    <section className="conatctus-container">
      <div className="contact-head">
        <h2>Contact Us </h2>
        <div className="about">
          <span>
            <Link to="/">Home</Link>
          </span>
          <i class="fas fa-solid fa-angle-right"></i>
          <Link to="/contact-us">Contact us</Link>
        </div>
      </div>

      <h2>Drop us message for any query</h2>
      

      <div className="contactus-form">
        <div className="contactus-left">
          <div className="left-card">
            <div className="left-img">
              <img src={pin} alt="" />
            </div>
            <div className="left-content">
              <h3>Location</h3>
              <p>
                2 D - 319 , Awas Vikas , Hanspuram, Naubasta, Kanpur -208021 ,
                India
              </p>
            </div>
          </div>
          <div className="left-card">
            <div className="left-img">
              <img src={email} alt="" />
            </div>
            <div className="left-content">
              <h3>Email</h3>
              <a href="mailto:lightequestrianinc@gmail.com">
                <p> lightequestrianinc@gmail.com</p>{" "}
              </a>

              {/* <a href="mailto: support@nexgpay.com"><p>support@nexgpaysolutions.com</p> </a> */}
            </div>
          </div>
          <div className="left-card">
            <div className="left-img">
              <img src={call} alt="" />
            </div>
            <div className="left-content">
              <h3>Phone</h3>
              <a href="tel:+919336338576">
                <p>+91 9336338576</p>{" "}
              </a>
            </div>
          </div>
          <div className="left-card">
            <div className="left-img">
             <img src={wa} alt="" />
            </div>
            <div className="left-content">
              <h3>Whatsapp</h3>
              <a href="tel:+918726275619">
                <p>+91 8726275619</p>{" "}
              </a>
            </div>
          </div>
        </div>

        <div className="contactus-right">
          <form ref={form} onSubmit={sendEmail}>
            <div class="inputBox">
              <div class="input">
                <span>Name</span>
                <input type="text" name="user_name" placeholder="Enter Your Name" required />
              </div>
              <div class="input">
                <span>Email</span>
                <input
                  type="email"
                  name="user_email"
                  placeholder="Enter Your Email"
                  required
                />
              </div>
            </div>
            <div class="inputBox">
              <div class="input">
                <span>Phone</span>
                <input
                  type="number"
                  name="user_phone"
                  placeholder="Enter Your Phone"
                  required
                />
              </div>
              <div class="input">
                <span>Subject</span>
                <input
                  type="test"
                  name="subject"
                  placeholder="Enter Your Subject"
                  required
                />
              </div>
            </div>

            <div class="inputBox">
              <div className="text-box">
                <span>Message</span>
                <textarea
                  name="message"
                  placeholder="Enter Your Message"
                  id=""
                  cols="30"
                  rows="10"
                  required
                ></textarea>
              </div>
            </div>
            {/* <div className="checkbox">
              <input type="checkbox" />
              By checking this, you agree to our{" "}
              <Link to="/privacypolicy">Terms and Privacy policy.</Link>
            </div> */}

            <div className="login">
              {/* <Link to="/login"> */}
              <button type="submit">Send Message</button>
              {/* </Link> */}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
