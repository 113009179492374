import React from "react";
import { Link } from "react-router-dom";
function SaddleCard({ saddle }) {
  return (
    <div className="saddle_card">
      <Link to={saddle.link}>
        <h3>{saddle.title}</h3>

        <div className="saddle-img">
          <img src={saddle.img} alt="image" />
          <div className="watermark">
            <p>
              Light <br /> Equestrian <br /> Inc.
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default SaddleCard;
