import React from "react";
import "./Home.css";
import Header from "../Templates/Header/Header";
import horse from "../Images/horse.jpg";

const data = {
  title: "Light Equestrian Inc.",
  para: "Light Equestrian Inc. India was established more than one decade ago,we manufacture & export full range of horse riding products made inbest quality of Leather as well as of Synthetic. Though we made almost every product related to horse riding Like Saddles, Bridles, Halters Ropes, Rugs, Horse Boots, Saddle pads, Harness Sets but our specialty is in making of Treeless Items like Treeless Saddles in Western Styles and English Styles and many design of Saddle pads and bareback pads.",
  img: horse,
};

function Home() {
  return (
    <>
      <Header data={data} />
    </>
  );
}

export default Home;
