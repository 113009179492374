import React from 'react'
import ComingSoon from '../../../CommingSoon/ComingSoon'

function SyntheticSaddles() {
  return (
    <div>
      <ComingSoon />
    </div>
  )
}

export default SyntheticSaddles
