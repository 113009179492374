import React from "react";
import WesternData from "../Saddle/SubSaddles/WesternData";
import ImageCard from "../../Templates/ImageCard";

function WesternSaddles() {
  return (
    <section>
      <h1>Briddles/Head Stall</h1>
      <div className="western_container">
        {WesternData.Briddles.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default WesternSaddles;
