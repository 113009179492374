import React from "react";
import WesternData from "./WesternData";
import ImageCard from "../../../Templates/ImageCard";

function SaddlePads() {
  return (
    <section>
      <h1>Saddle Pads</h1>
      <div className="western_container">
        {WesternData.SaddlePads.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default SaddlePads;
