import React from 'react'
// import ComingSoon from '../../CommingSoon/ComingSoon'
import WesternData from "../Saddle/SubSaddles/WesternData";
import ImageCard from "../../Templates/ImageCard";



function Halters() {
  return (
    <section>
      <h1>Halters</h1>
      <div className="western_container">
        {WesternData.Halters.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  )
}

export default Halters
