import React from "react";
import WesternData from "./WesternData";
import ImageCard from "../../../Templates/ImageCard";

function RacingSaddles() {
  return (
    <section>
      <h1> Racing Saddles</h1>
      <div className="western_container">
        {WesternData.RacingSaddles.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default RacingSaddles;
