import React from "react";
function ImageCard({ img }) {
  return (
    <div className="western_card">
      <img src={img.image} alt="image" />
      <div className="watermark wm">
        <p>
          Light <br /> Equestrian <br /> Inc.
        </p>
      </div>
      <h6>{img.h5}</h6>
    </div>
  );
}

export default ImageCard;
