//Western img
import img1 from "../../../Images/Saddles/BlackSeudeWestern.JPG";
import img2 from "../../../Images/Saddles/BS12Grp.JPG";
import img3 from "../../../Images/Saddles/0020.JPG";
import img4 from "../../../Images/Saddles/0023.JPG";
import img5 from "../../../Images/Saddles/108412.jpg";
import img6 from "../../../Images/Saddles/Browntreed1.JPG";
import img7 from "../../../Images/Saddles/BS12-1.JPG";
import img8 from "../../../Images/Saddles/BSI3.jpg";
import img9 from "../../../Images/Saddles/Haironwestern1.JPG";
import img10 from "../../../Images/Saddles/DSC04773.JPG";
import img11 from "../../../Images/Saddles/DSC05381.JPG";
import img12 from "../../../Images/Saddles/DSC05384.JPG";
import img13 from "../../../Images/Saddles/DSC05389.JPG";
import img14 from "../../../Images/Saddles/DSC04756.JPG";
import img15 from "../../../Images/Saddles/Haironwestern1.JPG";
import img16 from "../../../Images/Saddles/haironWesterns.JPG";
import img17 from "../../../Images/Saddles/Heritage.jpg";
import img18 from "../../../Images/Saddles/Heritage1.JPG";
import img19 from "../../../Images/Saddles/IMG-20161230-WA0024.jpg";
import img20 from "../../../Images/Saddles/NTC3.JPG";
import img21 from "../../../Images/Saddles/NTCGROUP.JPG";
import img22 from "../../../Images/Saddles/NTGROUP.JPG";
import img23 from "../../../Images/Saddles/NW1 clean.jpg";
import img24 from "../../../Images/Saddles/PinkWesternSeat.JPG";
import img25 from "../../../Images/Saddles/Treed Western2.JPG";
import img26 from "../../../Images/Saddles/Treed3.JPG";
import img27 from "../../../Images/Saddles/Treedwestern1.JPG";
import img28 from "../../../Images/Saddles/TreedWestern11.JPG";
import img29 from "../../../Images/Saddles/TreelessTurquiseGrp.JPG";
import img30 from "../../../Images/Saddles/TurquiseSeudeWestern.JPG";
import img31 from "../../../Images/Saddles/TurquiseTreelessWestern.JPG";
import img32 from "../../../Images/Saddles/WeekenderGrp.JPG";
import img33 from "../../../Images/Saddles/Western1.JPG";
import img34 from "../../../Images/Saddles/Western2.JPG";
import img35 from "../../../Images/Saddles/Western3.JPG";
import img36 from "../../../Images/Saddles/WSD15.jpg";
import img37 from "../../../Images/Saddles/Z2.JPG";
// western img

// Dressage Saddles

import img38 from "../../../Images/DressageSaddles/Cherry Dressage.JPG";
import img39 from "../../../Images/DressageSaddles/DRSG1.JPG";
import img40 from "../../../Images/DressageSaddles/DRSGBRWNMAIN.JPG";
import img41 from "../../../Images/DressageSaddles/Iceland Saddle.JPG";
import img42 from "../../../Images/DressageSaddles/Syntheticdressage.JPG";

// Jumping Saddles

import img43 from "../../../Images/JumpingSaddles/DSC01882.JPG";
import img44 from "../../../Images/JumpingSaddles/DSC02301.JPG";
import img45 from "../../../Images/JumpingSaddles/DSC02302.JPG";
import img46 from "../../../Images/JumpingSaddles/Equiroyal Horn.JPG";
import img47 from "../../../Images/JumpingSaddles/Equiroyal synthetic.jpg";
import img48 from "../../../Images/JumpingSaddles/Jumping Gullet2.JPG";
import img49 from "../../../Images/JumpingSaddles/KSH-2012[1].JPG";
import img50 from "../../../Images/JumpingSaddles/LeatherJMPGMAIN.JPG";
import img51 from "../../../Images/JumpingSaddles/LeatherJMPGMAINBLCK.JPG";
import img52 from "../../../Images/JumpingSaddles/Saddle_Marathon_Black_L.jpg";
import img53 from "../../../Images/JumpingSaddles/Syntheticjumping.JPG";

//Autralian Saddles

import img54 from "../../../Images/Autralian-Saddles/AKCASKS - 607.jpg";
import img55 from "../../../Images/Autralian-Saddles/AKC-ASKS-615.jpg";
import img56 from "../../../Images/Autralian-Saddles/Bhargo1.JPG";
import img57 from "../../../Images/Autralian-Saddles/BLUESTKGRP.JPG";
import img58 from "../../../Images/Autralian-Saddles/DSC01896.JPG";
import img59 from "../../../Images/Autralian-Saddles/DSC01904.JPG";
import img60 from "../../../Images/Autralian-Saddles/DSC01911.JPG";
import img61 from "../../../Images/Autralian-Saddles/DSC05363.JPG";
import img62 from "../../../Images/Autralian-Saddles/Hlfbrdblk1.JPG";
import img63 from "../../../Images/Autralian-Saddles/IMG0066A.bmp";
import img64 from "../../../Images/Autralian-Saddles/IMG-20170210-WA0010.jpg";
import img65 from "../../../Images/Autralian-Saddles/PINKSTGRP.JPG";
import img66 from "../../../Images/Autralian-Saddles/Synthetic-2.JPG";
import img67 from "../../../Images/Autralian-Saddles/Synthetic-1.JPG";
import img68 from "../../../Images/Autralian-Saddles/Syntheticstok1.JPG";

//Racing Saddles

import img70 from "../../../Images/Racing-Saddles/B2.JPG";
import img71 from "../../../Images/Racing-Saddles/DSC05158.JPG";
import img72 from "../../../Images/Racing-Saddles/DSC05351.JPG";
import img73 from "../../../Images/Racing-Saddles/DSC05354.JPG";
import img74 from "../../../Images/Racing-Saddles/R1.JPG";
import img75 from "../../../Images/Racing-Saddles/Racing-group.JPG";

// Saddle Pads

// import img76 from "../../../Images/SaddlePads/AKCASKS - 607.jpg";
// import img77 from "../../../Images/SaddlePads/AKC-ASKS-615.jpg";
// import img78 from "../../../Images/SaddlePads/Bhargo1.JPG";
// import img79 from "../../../Images/SaddlePads/BLUESTKGRP.JPG";
// import img80 from "../../../Images/SaddlePads/DSC01896.JPG";
// import img81 from "../../../Images/SaddlePads/DSC01904.JPG";
// import img82 from "../../../Images/SaddlePads/DSC01911.JPG";
import img83 from "../../../Images/SaddlePads/DSC03401.JPG";
import img84 from "../../../Images/SaddlePads/DSC03872.JPG";
import img85 from "../../../Images/SaddlePads/DSC04805.JPG";
import img86 from "../../../Images/SaddlePads/DSC04810.JPG";
import img87 from "../../../Images/SaddlePads/DSC04841.JPG";
import img88 from "../../../Images/SaddlePads/DSC04844.JPG";
import img89 from "../../../Images/SaddlePads/DSC05120.JPG";
import img90 from "../../../Images/SaddlePads/DSC05136.JPG";
import img91 from "../../../Images/SaddlePads/DSC05137.JPG";
import img92 from "../../../Images/SaddlePads/DSC05146.JPG";
import img93 from "../../../Images/SaddlePads/DSC05285.JPG";
import img94 from "../../../Images/SaddlePads/DSC05363.JPG";
import img95 from "../../../Images/SaddlePads/FELEPAD1.JPG";
import img96 from "../../../Images/SaddlePads/FELEPAD2.JPG";
import img97 from "../../../Images/SaddlePads/FELEPAD3.JPG";
import img98 from "../../../Images/SaddlePads/Hlfbrdblk1.JPG";
import img99 from "../../../Images/SaddlePads/IMG0066A.bmp";
import img100 from "../../../Images/SaddlePads/IMG-20170210-WA0010.jpg";
import img101 from "../../../Images/SaddlePads/Mini Cotton.JPG";
import img102 from "../../../Images/SaddlePads/Miniature Bareback Set.JPG";
import img103 from "../../../Images/SaddlePads/Newbarebacks.JPG";
import img104 from "../../../Images/SaddlePads/DSC05136.JPG";
import img105 from "../../../Images/SaddlePads/PINKSTGRP.JPG";
import img106 from "../../../Images/SaddlePads/RPads.JPG";
import img107 from "../../../Images/SaddlePads/SuedePad.JPG";
import img108 from "../../../Images/SaddlePads/SuedePad1.JPG";
import img109 from "../../../Images/SaddlePads/Synthetic-1.JPG";
import img110 from "../../../Images/SaddlePads/Synthetic-2.JPG";
import img111 from "../../../Images/SaddlePads/Syntheticstok1.JPG";
import img112 from "../../../Images/SaddlePads/Treeless saddle pads1.JPG";
import img113 from "../../../Images/SaddlePads/westernpadsgrp.JPG";
import img114 from "../../../Images/SaddlePads/IMAG1602.jpg";
import img115 from "../../../Images/SaddlePads/IMAG1604.jpg";
import img116 from "../../../Images/SaddlePads/IMAG1605.jpg";
import img117 from "../../../Images/SaddlePads/IMAG1607.jpg";
import img118 from "../../../Images/SaddlePads/IMAG1609.jpg";
import img119 from "../../../Images/SaddlePads/IMAG2182.jpg";
import img120 from "../../../Images/SaddlePads/IMAG2210.jpg";
import img121 from "../../../Images/SaddlePads/IMAG2304.jpg";
import img122 from "../../../Images/SaddlePads/IMAG2306.jpg";
import img123 from "../../../Images/SaddlePads/IMAG2308.jpg";
import img125 from "../../../Images/SaddlePads/IMAG2317.jpg";
import img126 from "../../../Images/SaddlePads/IMG-20180621-WA0011.jpg";
import img127 from "../../../Images/SaddlePads/NewJumpingPadset.JPG";
import img128 from "../../../Images/SaddlePads/NewWesternpadset.JPG";
import img130 from "../../../Images/SaddlePads/NewWesternPadwithhalterbridle.JPG";
import img131 from "../../../Images/SaddlePads/QPAD1.jpg";
import img132 from "../../../Images/SaddlePads/Racing pad.JPG";
import img133 from "../../../Images/SaddlePads/Zebra Bareback Set.JPG";

// Briddles

import img134 from "../../../Images/Briddles/15.jpg";
import img135 from "../../../Images/Briddles/16.jpg";
import img137 from "../../../Images/Briddles/17.jpg";
import img138 from "../../../Images/Briddles/18.jpg";
import img139 from "../../../Images/Briddles/20.jpg";
import img140 from "../../../Images/Briddles/21.jpg";
import img141 from "../../../Images/Briddles/23.jpg";
import img142 from "../../../Images/Briddles/3312Dark.jpg";
import img143 from "../../../Images/Briddles/15742all.jpg";
import img144 from "../../../Images/Briddles/B2.JPG";
import img145 from "../../../Images/Briddles/biothanebrdlhltr.JPG";
import img146 from "../../../Images/Briddles/Biothanebridle&Halter.JPG";
import img147 from "../../../Images/Briddles/bitlessbrwn.JPG";
import img148 from "../../../Images/Briddles/Copy of KI 7017.jpg";
import img149 from "../../../Images/Briddles/Copy of KI 7018 A.jpg";
import img150 from "../../../Images/Briddles/Copy of KI 7019.jpg";
import img152 from "../../../Images/Briddles/crystal halter.jpg";
import img153 from "../../../Images/Briddles/DSC01936.JPG";
import img154 from "../../../Images/Briddles/DSC02383.JPG";
import img155 from "../../../Images/Briddles/DSC05156.JPG";
import img156 from "../../../Images/Briddles/DSC05301.JPG";
import img157 from "../../../Images/Briddles/DSC05304.JPG";
import img158 from "../../../Images/Briddles/DSC05305.JPG";
import img159 from "../../../Images/Briddles/fullcavesson.jpg";
import img160 from "../../../Images/Briddles/halterblk.JPG";
import img161 from "../../../Images/Briddles/halterbrwn.JPG";
import img162 from "../../../Images/Briddles/IMAG1100.jpg";
import img163 from "../../../Images/Briddles/IMAG1471.jpg";
import img164 from "../../../Images/Briddles/IMAG1775.jpg";
import img165 from "../../../Images/Briddles/IMG-20170701-WA0014.jpg";
import img166 from "../../../Images/Briddles/IMG-20190530-WA0004.jpg";
import img167 from "../../../Images/Briddles/IMG-20200114-WA0053.jpg";
import img168 from "../../../Images/Briddles/KnottingEarBridle.JPG";
import img169 from "../../../Images/Briddles/KnottingHeadstall.JPG";
import img170 from "../../../Images/Briddles/LH-02.jpg";
import img171 from "../../../Images/Briddles/N1.jpg";
import img172 from "../../../Images/Briddles/NewBridle2.JPG";
import img173 from "../../../Images/Briddles/NewBridle3.JPG";
import img174 from "../../../Images/Briddles/Nylonheadstall&breastplate2.JPG";
import img176 from "../../../Images/Briddles/pinkset.JPG";
import img177 from "../../../Images/Briddles/REI-B04.jpg";
import img178 from "../../../Images/Briddles/REI-B05.jpg";
import img179 from "../../../Images/Briddles/REI-B06.jpg";
import img180 from "../../../Images/Briddles/REI-B11.jpg";
import img181 from "../../../Images/Briddles/reins.jpg";

// All Purpose Saddles

import img182 from "../../../Images/AllPurpose/Black1.JPG";
import img183 from "../../../Images/AllPurpose/Blue-Net1.JPG";
import img184 from "../../../Images/AllPurpose/Brcotton1.JPG";
import img185 from "../../../Images/AllPurpose/DSC05282.JPG";
import img186 from "../../../Images/AllPurpose/endurance.JPG";
import img187 from "../../../Images/AllPurpose/Endurance1.JPG";
import img188 from "../../../Images/AllPurpose/FMJMPGLTHRBLCKMAIN.JPG";
import img189 from "../../../Images/AllPurpose/Fullwhtcotton1.JPG";
import img190 from "../../../Images/AllPurpose/Magenta1ss.JPG";
import img191 from "../../../Images/AllPurpose/Morepic.JPG";
import img192 from "../../../Images/AllPurpose/New11.JPG";
import img193 from "../../../Images/AllPurpose/Newbuckwestern clean.jpg";
import img194 from "../../../Images/AllPurpose/NEWTRLSGRP.JPG";
import img195 from "../../../Images/AllPurpose/Orng1ss.JPG";
import img196 from "../../../Images/AllPurpose/pinkfmgrp.JPG";
import img197 from "../../../Images/AllPurpose/Red1.JPG";
import img198 from "../../../Images/AllPurpose/Redcotton1.JPG";
import img199 from "../../../Images/AllPurpose/RedKneeonfender.JPG";
import img200 from "../../../Images/AllPurpose/redtk2.JPG";
import img201 from "../../../Images/AllPurpose/redtk3.JPG";
import img202 from "../../../Images/AllPurpose/spanish-saddle-.jpg";
import img203 from "../../../Images/AllPurpose/Yellow1ss.JPG";

// Horse Rugs

import img204 from "../../../Images/HorseRugs/1.JPG";
import img205 from "../../../Images/HorseRugs/2.JPG";
import img206 from "../../../Images/HorseRugs/3.JPG";
import img207 from "../../../Images/HorseRugs/B1.JPG";
import img208 from "../../../Images/HorseRugs/Blk.JPG";
import img209 from "../../../Images/HorseRugs/Blue.JPG";
import img210 from "../../../Images/HorseRugs/Blueblk.JPG";
import img211 from "../../../Images/HorseRugs/DSC03321.JPG";
import img212 from "../../../Images/HorseRugs/fix neck.JPG";
import img213 from "../../../Images/HorseRugs/Grn.JPG";
import img214 from "../../../Images/HorseRugs/Grn-2.JPG";
import img215 from "../../../Images/HorseRugs/grnblk.JPG";
import img216 from "../../../Images/HorseRugs/Pink.JPG";
import img217 from "../../../Images/HorseRugs/R1.JPG";
import img218 from "../../../Images/HorseRugs/R2.JPG";
import img219 from "../../../Images/HorseRugs/removeable neck.JPG";
import img220 from "../../../Images/HorseRugs/Yellow-blk.JPG";
import img221 from "../../../Images/HorseRugs/Yellow-blk-2.JPG";

// Horse Boots
import img222 from "../../../Images/HorseBoots/BR1.jpg";
import img223 from "../../../Images/HorseBoots/GN1.jpg";
import img224 from "../../../Images/HorseBoots/IMAG2367.jpg";
import img225 from "../../../Images/HorseBoots/IMG-20160814-WA0003.jpg";
import img226 from "../../../Images/HorseBoots/IMG-20160814-WA0005.jpg";
import img227 from "../../../Images/HorseBoots/IMG-20160814-WA0011.jpg";
import img228 from "../../../Images/HorseBoots/IMG-20160814-WA0014.jpg";
import img229 from "../../../Images/HorseBoots/IMG-20160814-WA0015.jpg";
import img230 from "../../../Images/HorseBoots/IMG-20160814-WA0017.jpg";
import img231 from "../../../Images/HorseBoots/IMG-20161111-WA0011.jpg";
import img232 from "../../../Images/HorseBoots/IMG-20170817-WA0011.jpg";
import img233 from "../../../Images/HorseBoots/IMG-20170817-WA0013.jpg";
import img234 from "../../../Images/HorseBoots/IMG-20170923-WA0045.jpg";
import img235 from "../../../Images/HorseBoots/IMG-20170923-WA0049.jpg";
import img236 from "../../../Images/HorseBoots/IMG-20170923-WA0055.jpg";
import img237 from "../../../Images/HorseBoots/IMG-20170923-WA0056.jpg";
import img238 from "../../../Images/HorseBoots/IMG-20170923-WA0057.jpg";
import img239 from "../../../Images/HorseBoots/IMG-20170923-WA0061.jpg";
import img240 from "../../../Images/HorseBoots/IMG-20170923-WA0085.jpg";

//Harness Sets

import img241 from "../../../Images/HarnessSets/Biothane Racing 2.jpg";
import img242 from "../../../Images/HarnessSets/Biothane-Singleharness.JPG";
import img243 from "../../../Images/HarnessSets/BSI-HRNS1.JPG";
import img244 from "../../../Images/HarnessSets/BSI-HRNS2.JPG";
import img245 from "../../../Images/HarnessSets/BSI-HRNS3.JPG";
import img246 from "../../../Images/HarnessSets/Collar-Style.JPG";
import img247 from "../../../Images/HarnessSets/Diplomat.JPG";
import img248 from "../../../Images/HarnessSets/Doubleharness(full).JPG";
import img249 from "../../../Images/HarnessSets/DSC05330.JPG";
import img250 from "../../../Images/HarnessSets/DSC05332.JPG";
import img251 from "../../../Images/HarnessSets/DSC05333.JPG";
import img252 from "../../../Images/HarnessSets/DSC05334.JPG";
import img253 from "../../../Images/HarnessSets/DSC05336.JPG";
import img254 from "../../../Images/HarnessSets/DSC05337.JPG";
import img255 from "../../../Images/HarnessSets/DSC05338.JPG";
import img256 from "../../../Images/HarnessSets/DSC05339.JPG";
import img257 from "../../../Images/HarnessSets/DSC05340.JPG";
import img258 from "../../../Images/HarnessSets/DSC05341.JPG";
import img259 from "../../../Images/HarnessSets/harnais 3.JPG";
import img260 from "../../../Images/HarnessSets/IMG-20170129-WA0001.jpg";
import img261 from "../../../Images/HarnessSets/Miniature-1.JPG";
import img262 from "../../../Images/HarnessSets/Miniature-2.JPG";
import img263 from "../../../Images/HarnessSets/Miniature-3.JPG";
import img264 from "../../../Images/HarnessSets/Miniature-5.JPG";
import img265 from "../../../Images/HarnessSets/Miniature-4.JPG";
import img266 from "../../../Images/HarnessSets/Miniature-Team.JPG";
import img267 from "../../../Images/HarnessSets/Nylon Harness set.JPG";
import img268 from "../../../Images/HarnessSets/Nylon pair.jpg";
import img269 from "../../../Images/HarnessSets/Nylon.JPG";
import img270 from "../../../Images/HarnessSets/Nylon-B.jpg";
import img271 from "../../../Images/HarnessSets/PVC ( Biothane).JPG";
import img272 from "../../../Images/HarnessSets/Racing Harness.JPG";
import img273 from "../../../Images/HarnessSets/Singleharness(cob).JPG";

// Accessories

import img274 from "../../../Images/Accessories/br1.jpg";
import img275 from "../../../Images/Accessories/br5.jpg";
import img276 from "../../../Images/Accessories/breech a 11.jpg";
import img277 from "../../../Images/Accessories/breech a 12.jpg";
import img278 from "../../../Images/Accessories/DSC01937.JPG";
import img279 from "../../../Images/Accessories/DSC01999.JPG";
import img280 from "../../../Images/Accessories/DSC02000.JPG";
import img281 from "../../../Images/Accessories/DSC02217.JPG";
import img282 from "../../../Images/Accessories/DSC02226.JPG";
import img283 from "../../../Images/Accessories/hfchap3.JPG";
import img284 from "../../../Images/Accessories/IMG-20160814-WA0004.jpg";
import img285 from "../../../Images/Accessories/IMG-20160814-WA0007.jpg";
import img286 from "../../../Images/Accessories/IMG-20161111-WA0011.jpg";
import img287 from "../../../Images/Accessories/IMG-20161112-WA0001.jpg";
import img288 from "../../../Images/Accessories/IMG-20161112-WA0004.jpg";
import img289 from "../../../Images/Accessories/IMG-20170923-WA0050.jpg";
import img290 from "../../../Images/Accessories/IMG-20170923-WA0066.jpg";
import img291 from "../../../Images/Accessories/IMG-20170923-WA0087.jpg";
import img292 from "../../../Images/Accessories/IMG-20180127-WA0032.jpg";
import img293 from "../../../Images/Accessories/IMG-20190628-WA0007.jpg";
import img294 from "../../../Images/Accessories/IMG-20190628-WA0013.jpg";
import img295 from "../../../Images/Accessories/IMG-20190724-WA0004.jpg";
import img296 from "../../../Images/Accessories/IMG-20190724-WA0005.jpg";
import img297 from "../../../Images/Accessories/IMG-20190924-WA0011.jpg";
import img298 from "../../../Images/Accessories/IMG-20200702-WA0008.jpg";
import img299 from "../../../Images/Accessories/IMG-20200729-WA0023.jpg";
import img300 from "../../../Images/Accessories/IMG20220727114615.jpg";
import img301 from "../../../Images/Accessories/IMG20220727114806.jpg";
import img302 from "../../../Images/Accessories/IMG20220727114855.jpg";
import img303 from "../../../Images/Accessories/IMG20220727115057.jpg";
import img304 from "../../../Images/Accessories/JMPBag1.JPG";
import img305 from "../../../Images/Accessories/leather-girth.jpg";
import img306 from "../../../Images/Accessories/received_324831254655565.jpeg";
import img307 from "../../../Images/Accessories/S1.JPG";
import img308 from "../../../Images/Accessories/Saddle bag.JPG";

/// new category
import img309 from "../../../Images/NC/nc1.jpeg";
import img310 from "../../../Images/NC/nc2.jpeg";
import img311 from "../../../Images/NC/nc3.jpg";
import img312 from "../../../Images/NC/nc4.jpg";
import img313 from "../../../Images/NC/nc5.jpg";

export default {
  WesternImages: [
    {
      image: img1,
      h5: "WS1001",
    },
    {
      image: img2,
      h5: "WS1002",
    },
    {
      image: img3,
      h5: "WS1003",
    },
    {
      image: img4,
      h5: "WS1004",
    },
    {
      image: img5,
      h5: "WS1005",
    },
    {
      image: img6,
      h5: "WS1006",
    },
    {
      image: img7,
      h5: "WS1007",
    },
    {
      image: img8,
      h5: "WS1008",
    },
    {
      image: img9,
      h5: "WS1009",
    },
    {
      image: img10,
      h5: "WS1010",
    },
    {
      image: img11,
      h5: "WS1011",
    },
    {
      image: img12,
      h5: "WS1012",
    },
    {
      image: img13,
      h5: "WS1013",
    },
    {
      image: img14,
      h5: "WS1014",
    },
    {
      image: img15,
      h5: "WS1015",
    },
    {
      image: img16,
      h5: "WS1016",
    },
    {
      image: img17,
      h5: "WS1017",
    },
    {
      image: img18,
      h5: "WS1018",
    },
    {
      image: img19,
      h5: "WS1019",
    },
    {
      image: img21,
      h5: "WS1020",
    },
    {
      image: img22,
      h5: "WS1021",
    },
    {
      image: img23,
      h5: "WS1022",
    },
    {
      image: img24,
      h5: "WS1023",
    },
    {
      image: img25,
      h5: "WS1024",
    },
    {
      image: img26,
      h5: "WS1025",
    },
    {
      image: img27,
      h5: "WS1026",
    },
    {
      image: img28,
      h5: "WS1027",
    },
    {
      image: img29,
      h5: "WS1028",
    },
    {
      image: img30,
      h5: "WS1029",
    },
    {
      image: img31,
      h5: "WS1030",
    },
    {
      image: img32,
      h5: "WS1031",
    },
    {
      image: img33,
      h5: "WS1032",
    },
    {
      image: img34,
      h5: "WS1033",
    },
    {
      image: img35,
      h5: "WS1034",
    },
    {
      image: img36,
      h5: "WS1035",
    },
    {
      image: img37,
      h5: "WS1036",
    },
  ],

  DressageSaddles: [
    {
      image: img38,
      h5: "DS1001",
    },
    {
      image: img39,
      h5: "DS1002",
    },
    {
      image: img40,
      h5: "DS1003",
    },
    {
      image: img41,
      h5: "DS1004",
    },
    {
      image: img42,
      h5: "DS1005",
    },
  ],

  JumpingSaddles: [
    {
      image: img43,
      h5: "JS1001",
    },
    {
      image: img44,
      h5: "JS1002",
    },
    {
      image: img45,
      h5: "JS1003",
    },
    {
      image: img46,
      h5: "JS1004",
    },
    {
      image: img47,
      h5: "JS1005",
    },
    {
      image: img48,
      h5: "JS1006",
    },
    {
      image: img49,
      h5: "JS1007",
    },
    {
      image: img50,
      h5: "JS1008",
    },
    {
      image: img51,
      h5: "JS1009",
    },
    {
      image: img52,
      h5: "JS1010",
    },
    {
      image: img53,
      h5: "JS1011",
    },
  ],

  AustralianSaddles: [
    {
      image: img54,
      h5: "AS1001",
    },
    {
      image: img55,
      h5: "AS1002",
    },
    {
      image: img56,
      h5: "AS1003",
    },
    {
      image: img57,
      h5: "AS1004",
    },
    {
      image: img58,
      h5: "AS1005",
    },
    {
      image: img59,
      h5: "AS1006",
    },

    {
      image: img60,
      h5: "AS1007",
    },
    {
      image: img61,
      h5: "AS1008",
    },
    {
      image: img62,
      h5: "AS1009",
    },
    {
      image: img63,
      h5: "AS1010",
    },
    {
      image: img64,
      h5: "AS1011",
    },
    {
      image: img65,
      h5: "AS1012",
    },
    {
      image: img66,
      h5: "AS1013",
    },
    {
      image: img67,
      h5: "AS1014",
    },
    {
      image: img68,
      h5: "AS1015",
    },
  ],
  RacingSaddles: [
    {
      image: img70,
      h5: "RS1001",
    },
    {
      image: img71,
      h5: "RS1002",
    },
    {
      image: img72,
      h5: "RS1003",
    },
    {
      image: img73,
      h5: "RS1004",
    },
    {
      image: img74,
      h5: "RS1005",
    },
    {
      image: img75,
      h5: "RS1006",
    },
  ],
  SaddlePads: [
    {
      image: img83,
      h5: "SP1008",
    },
    {
      image: img84,
      h5: "SP1009",
    },
    {
      image: img85,
      h5: "SP1010",
    },
    {
      image: img86,
      h5: "SP1011",
    },
    {
      image: img87,
      h5: "SP1012",
    },
    {
      image: img88,
      h5: "SP1013",
    },
    {
      image: img89,
      h5: "SP1014",
    },
    {
      image: img90,
      h5: "SP1015",
    },
    {
      image: img91,
      h5: "SP1016",
    },
    {
      image: img92,
      h5: "SP1017",
    },
    {
      image: img93,
      h5: "SP1018",
    },
    {
      image: img94,
      h5: "SP1019",
    },
    {
      image: img95,
      h5: "SP1020",
    },
    {
      image: img96,
      h5: "SP1021",
    },
    {
      image: img97,
      h5: "SP1022",
    },
    {
      image: img101,
      h5: "SP1023",
    },
    {
      image: img102,
      h5: "SP1024",
    },
    {
      image: img103,
      h5: "SP1025",
    },
    {
      image: img104,
      h5: "SP1026",
    },

    {
      image: img106,
      h5: "SP1027",
    },
    {
      image: img107,
      h5: "SP1028",
    },
    {
      image: img108,
      h5: "SP1029",
    },

    {
      image: img112,
      h5: "SP1030",
    },
    {
      image: img113,
      h5: "SP1031",
    },
    {
      image: img114,
      h5: "SP1032",
    },
    {
      image: img115,
      h5: "SP1033",
    },
    {
      image: img116,
      h5: "SP1034",
    },
    {
      image: img117,
      h5: "SP1035",
    },
    {
      image: img118,
      h5: "SP1036",
    },
    {
      image: img119,
      h5: "SP1037",
    },
    {
      image: img120,
      h5: "SP1038",
    },
    {
      image: img121,
      h5: "SP1039",
    },
    {
      image: img122,
      h5: "SP1040",
    },
    {
      image: img123,
      h5: "SP1041",
    },
    {
      image: img125,
      h5: "SP1042",
    },
    {
      image: img126,
      h5: "SP1043",
    },
    {
      image: img127,
      h5: "SP1044",
    },
    {
      image: img128,
      h5: "SP1045",
    },
    {
      image: img132,
      h5: "SP1046",
    },
    {
      image: img130,
      h5: "SP1047",
    },
    {
      image: img131,
      h5: "SP1048",
    },
    {
      image: img133,
      h5: "SP1049",
    },
  ],

  Halters: [
    {
      image: img145,
      h5: "HA1001",
    },
    {
      image: img143,
      h5: "BR1009",
    },
    {
      image: img146,
      h5: "BR1013",
    },
    {
      image: img152,
      h5: "BR1017",
    },
    {
      image: img155,
      h5: "BR1020",
    },
    {
      image: img160,
      h5: "BR1024",
    },
    {
      image: img161,
      h5: "BR1025",
    },
    {
      image: img170,
      h5: "BR1034",
    },

  ],

  Briddles: [
    {
      image: img134,
      h5: "BR1001",
    },
    {
      image: img135,
      h5: "BR1002",
    },
    {
      image: img138,
      h5: "BR1003",
    },
    {
      image: img137,
      h5: "BR1004",
    },
    {
      image: img139,
      h5: "BR1005",
    },
    {
      image: img140,
      h5: "BR1006",
    },
    {
      image: img141,
      h5: "BR1007",
    },
    {
      image: img142,
      h5: "BR1008",
    },

    {
      image: img144,
      h5: "BR1010",
    },

    {
      image: img150,
      h5: "BR1012",
    },

    {
      image: img147,
      h5: "BR1014",
    },
    {
      image: img148,
      h5: "BR1015",
    },
    {
      image: img149,
      h5: "BR1016",
    },

    {
      image: img153,
      h5: "BR1018",
    },
    {
      image: img154,
      h5: "BR1019",
    },

    {
      image: img156,
      h5: "BR1021",
    },
    {
      image: img157,
      h5: "BR1022",
    },
    {
      image: img158,
      h5: "BR1023",
    },

    {
      image: img162,
      h5: "BR1026",
    },
    {
      image: img163,
      h5: "BR1027",
    },
    {
      image: img164,
      h5: "BR1028",
    },
    {
      image: img165,
      h5: "BR1029",
    },
    {
      image: img166,
      h5: "BR1030",
    },
    {
      image: img167,
      h5: "BR1031",
    },
    {
      image: img168,
      h5: "BR1032",
    },
    {
      image: img169,
      h5: "BR1033",
    },

    {
      image: img171,
      h5: "BR1035",
    },
    {
      image: img172,
      h5: "BR1036",
    },
    {
      image: img173,
      h5: "BR1037",
    },
    {
      image: img174,
      h5: "BR1038",
    },
    {
      image: img176,
      h5: "BR1039",
    },
    {
      image: img177,
      h5: "BR1040",
    },
    {
      image: img178,
      h5: "BR1041",
    },
    {
      image: img179,
      h5: "BR1042",
    },
    {
      image: img180,
      h5: "BR1043",
    },
    {
      image: img181,
      h5: "BR1044",
    },
  ],

  AllPurposeSaddles: [
    {
      image: img182,
      h5: "APS1001",
    },
    {
      image: img183,
      h5: "APS1002",
    },
    {
      image: img184,
      h5: "APS1003",
    },
    {
      image: img185,
      h5: "APS1004",
    },
    {
      image: img186,
      h5: "APS1005",
    },
    {
      image: img187,
      h5: "APS1006",
    },

    {
      image: img188,
      h5: "APS1007",
    },
    {
      image: img189,
      h5: "APS1008",
    },
    {
      image: img190,
      h5: "APS1009",
    },
    {
      image: img191,
      h5: "APS1010",
    },
    {
      image: img192,
      h5: "APS1011",
    },
    {
      image: img193,
      h5: "APS1012",
    },
    {
      image: img194,
      h5: "APS1013",
    },
    {
      image: img195,
      h5: "APS1014",
    },
    {
      image: img196,
      h5: "APS1015",
    },
    {
      image: img197,
      h5: "APS1016",
    },
    {
      image: img198,
      h5: "APS1017",
    },
    {
      image: img199,
      h5: "APS1018",
    },
    {
      image: img200,
      h5: "APS1019",
    },
    {
      image: img201,
      h5: "APS1020",
    },
    {
      image: img202,
      h5: "APS1021",
    },
    {
      image: img203,
      h5: "APS1022",
    },
  ],
  HorseRugs: [
    {
      image: img204,
      h5: "HR1001",
    },
    {
      image: img205,
      h5: "HR1002",
    },
    {
      image: img206,
      h5: "HR1003",
    },
    {
      image: img207,
      h5: "HR1004",
    },
    {
      image: img208,
      h5: "HR1005",
    },
    {
      image: img209,
      h5: "HR1006",
    },

    {
      image: img210,
      h5: "HR1007",
    },
    {
      image: img211,
      h5: "HR1008",
    },

    {
      image: img212,
      h5: "HR1009",
    },
    {
      image: img213,
      h5: "HR1010",
    },
    {
      image: img214,
      h5: "HR1011",
    },
    {
      image: img215,
      h5: "HR1012",
    },
    {
      image: img216,
      h5: "HR1013",
    },
    {
      image: img217,
      h5: "HR1014",
    },
    {
      image: img218,
      h5: "HR1015",
    },
    {
      image: img219,
      h5: "HR1016",
    },
    {
      image: img220,
      h5: "HR1017",
    },
    {
      image: img221,
      h5: "HR1018",
    },
  ],

  HorseBoots: [
    {
      image: img222,
      h5: "HB1001",
    },
    {
      image: img223,
      h5: "HB1002",
    },
    {
      image: img224,
      h5: "HB1003",
    },
    {
      image: img225,
      h5: "HB1004",
    },
    {
      image: img226,
      h5: "HB1005",
    },
    {
      image: img227,
      h5: "HB1006",
    },
    {
      image: img228,
      h5: "HB1007",
    },
    {
      image: img229,
      h5: "HB1008",
    },
    {
      image: img230,
      h5: "HB1009",
    },
    {
      image: img231,
      h5: "HB1010",
    },
    {
      image: img232,
      h5: "HB1011",
    },
    {
      image: img233,
      h5: "HB1012",
    },
    {
      image: img234,
      h5: "HB1013",
    },
    {
      image: img235,
      h5: "HB1014",
    },
    {
      image: img236,
      h5: "HB1015",
    },
    {
      image: img237,
      h5: "HB1016",
    },
    {
      image: img238,
      h5: "HB1017",
    },
    {
      image: img239,
      h5: "HB1018",
    },
    {
      image: img240,
      h5: "HB1019",
    },


  ],

  HarnesSets: [
    {
      image: img241,
      h5: "HS1001",
    },
    {
      image: img242,
      h5: "HS1002",
    },
    {
      image: img243,
      h5: "HS1003",
    },
    {
      image: img244,
      h5: "HS1004",
    },
    {
      image: img245,
      h5: "HS1005",
    },
    {
      image: img246,
      h5: "HS1006",
    },

    {
      image: img247,
      h5: "HS1007",
    },
    {
      image: img248,
      h5: "HS1008",
    },

    {
      image: img249,
      h5: "HS1009",
    },
    {
      image: img250,
      h5: "HS1010",
    },
    {
      image: img251,
      h5: "HS1011",
    },
    {
      image: img252,
      h5: "HS1012",
    },
    {
      image: img253,
      h5: "HS1013",
    },
    {
      image: img254,
      h5: "HS1014",
    },
    {
      image: img255,
      h5: "HS1015",
    },
    {
      image: img256,
      h5: "HS1016",
    },
    {
      image: img257,
      h5: "HS1017",
    },
    {
      image: img258,
      h5: "HS1018",
    },
    {
      image: img259,
      h5: "HS1019",
    },
    {
      image: img260,
      h5: "HS1020",
    },
    {
      image: img261,
      h5: "HS1021",
    },
    {
      image: img262,
      h5: "HS1022",
    },
    {
      image: img263,
      h5: "HS1023",
    },
    {
      image: img264,
      h5: "HS1024",
    },
    {
      image: img265,
      h5: "HS1025",
    },
    {
      image: img266,
      h5: "HS1026",
    },
    {
      image: img267,
      h5: "HS1027",
    },
    {
      image: img268,
      h5: "HS1028",
    },
    {
      image: img269,
      h5: "HS1029",
    },
    {
      image: img270,
      h5: "HS1030",
    },
    {
      image: img271,
      h5: "HS1031",
    },
    {
      image: img272,
      h5: "HS1032",
    },
    {
      image: img273,
      h5: "HS1033",
    },
  ],
  Accessories: [
    {
      image: img274,
      h5: "AC1001",
    },
    {
      image: img275,
      h5: "AC1002",
    },
    {
      image: img276,
      h5: "AC1003",
    },
    {
      image: img277,
      h5: "AC1004",
    },
    {
      image: img278,
      h5: "AC1005",
    },
    {
      image: img279,
      h5: "AC1006",
    },

    {
      image: img280,
      h5: "AC1007",
    },
    {
      image: img281,
      h5: "AC1008",
    },

    {
      image: img282,
      h5: "AC1009",
    },
    {
      image: img283,
      h5: "AC1010",
    },
    {
      image: img284,
      h5: "AC1011",
    },
    {
      image: img285,
      h5: "AC1012",
    },
    {
      image: img286,
      h5: "AC1013",
    },
    {
      image: img287,
      h5: "AC1014",
    },
    {
      image: img288,
      h5: "AC1015",
    },
    {
      image: img289,
      h5: "AC1016",
    },
    {
      image: img290,
      h5: "AC1017",
    },
    {
      image: img291,
      h5: "AC1018",
    },
    {
      image: img292,
      h5: "AC1019",
    },
    {
      image: img293,
      h5: "AC1020",
    },
    {
      image: img294,
      h5: "AC1021",
    },
    {
      image: img295,
      h5: "AC1022",
    },
    {
      image: img296,
      h5: "AC1023",
    },
    {
      image: img297,
      h5: "AC1024",
    },
    {
      image: img298,
      h5: "AC1025",
    },
    {
      image: img299,
      h5: "AC1026",
    },
    {
      image: img300,
      h5: "AC1027",
    },
    {
      image: img301,
      h5: "AC1028",
    },
    {
      image: img302,
      h5: "AC1029",
    },
    {
      image: img303,
      h5: "AC1030",
    },
    {
      image: img304,
      h5: "AC1031",
    },
    {
      image: img305,
      h5: "AC1032",
    },
    {
      image: img306,
      h5: "AC1033",
    },
    {
      image: img307,
      h5: "AC1034",
    },
    {
      image: img308,
      h5: "AC1035",
    },
  ],

  NC: [
    {
      image: img309,
      h5: "NC1001",
    },
    {
      image: img310,
      h5: "NC1002",
    },
    {
      image: img311,
      h5: "NC1003",
    },
    {
      image: img312,
      h5: "NC1004",
    },
    {
      image: img313,
      h5: "NC1005",
    },
  ],
};
