import "./App.css";
import Home from "./component/Home/Home";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer/Footer";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import AboutUs from "./component/AboutUs/AboutUs";
import ContactUs from "./component/ContactUs/ContactUs";

import Accessories from "./component/Products/Accessories/Accessories";
import Briddles from "./component/Products/Briddles/Briddles";
import Halters from "./component/Products/Halters/Halters";
import HorseBoot from "./component/Products/Horse-Boot/HorseBoot";
import Rags from "./component/Products/Rags/Rags";
import Saddle from "./component/Products/Saddle/Saddle";
import SdalePads from "./component/Products/Sdale-Pads/SdalePads";
import WesternSaddles from "./component/Products/Saddle/SubSaddles/WesternSaddle/WesternSaddles";
import AustralianSaddles from "./component/Products/Saddle/SubSaddles/AustralianSaddles";
import EnglishSaddles from "./component/Products/Saddle/SubSaddles/EnglishSaddles/EnglishSaddles";
import RacingSaddles from "./component/Products/Saddle/SubSaddles/RacingSaddles";
import SyntheticSaddles from "./component/Products/Saddle/SubSaddles/SyntheticSaddles";

import DressageSaddles from "./component/Products/Saddle/SubSaddles/EnglishSaddles/DressageSaddles";
import JumpingSaddles from "./component/Products/Saddle/SubSaddles/EnglishSaddles/JumpingSaddles";

import SaddlePads from "./component/Products/Saddle/SubSaddles/SaddlePads";
import AllPurposeSaddles from "./component/Products/Saddle/SubSaddles/All-purpose-Saddles";
import HarnessSets from "./component/Products/HarnessSets/HarnessSets";
import NewCatewgory from "./component/Products/NewCategory/NewCatewgory";

function App() {


  // template = process.env.TEMPLATE_ID;
  // service = process.env.PUBLIC_KRY;
  // public_key = process.env.PUBLIC_KEY;


  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <>
        <Navbar />
        <Routes>         
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/accessories" element={<Accessories />} />
          <Route exact path="/briddles" element={<Briddles />} />
          <Route exact path="/halters" element={<Halters />} />
          <Route exact path="/rugs" element={<Rags />} />
          <Route exact path="/horseboot" element={<HorseBoot />} />
          <Route exact path="/harness-sets" element={<HarnessSets />} />
          <Route exact path="/saddles" element={<Saddle />} />
          <Route exact path="/new-category" element={<NewCatewgory />} />
          {/* Sub Saddles */}
          <Route
            exact
            path="/saddles/western-saddles"
            element={<WesternSaddles />}
          />
          <Route
            exact
            path="/saddles/australian-saddles"
            element={<AustralianSaddles />}
          />
          <Route
            exact
            path="/saddles/english-saddles"
            element={<EnglishSaddles />}
          />
          {/* Sub English Saddles */}
          <Route
            exact
            path="/saddles/english-saddles/jumping-saddles"
            element={<DressageSaddles />}
          />
          <Route
            exact
            path="/saddles/english-saddles/dressage-saddles"
            element={<JumpingSaddles />}
          />

          <Route
            exact
            path="/saddles/racing-saddles"
            element={<RacingSaddles />}
          />
          <Route
            exact
            path="/saddles/synthetic-saddles"
            element={<SyntheticSaddles />}
          />
          <Route
            exact
            path="/saddles/all-purpose-saddles"
            element={<AllPurposeSaddles />}
          />

          <Route exact path="/saddles/saddle-pads" element={<SaddlePads />} />

          <Route exact path="/sdalepads" element={<SdalePads />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
        <Footer />
      </>
    </div>
  );
}

export default App;
