import React from "react";
import saddle1 from "../../Images/Saddles/Western1.JPG";
import saddle2 from "../../Images/DressageSaddles/DRSG1.JPG";
import saddle3 from "../../Images/Autralian-Saddles/AKC-ASKS-615.jpg";
import saddle4 from "../../Images/Racing-Saddles/B2.JPG";
import saddle5 from "../../Images/Coming.png";
import saddle6 from "../../Images/SaddlePads/IMAG1602.jpg";
import saddle7 from "../../Images/AllPurpose/spanish-saddle-.jpg";

import "./Saddle.css";
import SaddleCard from "../../Templates/SaddleCard";
import { Watermark } from "antd";

const data = {
  title: "Saddles",
  para: "The saddle is a supportive structure for a rider of an animal, fastened to an animal's back by a girth. The most common type is equestrian. However, specialized saddles have been created for oxen, camels and other animals.[1] It is not known precisely when riders first began to use some sort of padding or protection, but a blanket attached by some form of surcingle or girth was probably the first 'saddle', followed later by more elaborate padded designs.",
};

const data1 = [
  {
    title: "Western Saddles",
    img: saddle1,
    link: "/saddles/western-saddles",
  },
  {
    title: "English Saddles",
    img: saddle2,
    link: "/saddles/english-saddles",
  },

  {
    title: "Australian Saddles",
    img: saddle3,
    link: "/saddles/australian-saddles",
  },

  {
    title: "Racing Saddles",
    img: saddle4,
    link: "/saddles/racing-saddles",
  },


  {
    title: "Saddle Pads",
    img: saddle6,
    link: "/saddles/saddle-pads",
  },
  {
    title: "All Purpose Saddle",
    img: saddle7,
    link: "/saddles/all-purpose-saddles",
  },
];

function Saddle() {
  return (
    <>
      <section className="home-container">
        <div className="home-left">
          <h1>{data.title}</h1>
          <h4>{data.para}</h4>
        </div>
        <div className="saddle-right">
          <h1>Types of Saddles</h1>
          <div className="saddles_container">
            {data1.map((saddle) => (
              <SaddleCard saddle={saddle} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Saddle;
