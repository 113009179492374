import React from "react";
import "./AboutUs.css";
// import logo from "../Images/nexGPay-Logo.png";
// import open from "../Images/open.png";
// import card from "../Images/card.png";
import horse1 from "../Images/horse.jpg";
import { Link } from "react-router-dom";



function AboutUs() {
    return (
        <section className="aboutus-container">
            <div className="about-head">
                <h2>LIGHT EQUESTRIAN INC. INDIA.</h2>
                <div className="about">
                    <span><Link to="/">Home</Link></span>
                    <i class="fas fa-solid fa-angle-right"></i>
                    <Link to="/about-us">About us</Link>
                </div>
            </div>

            {/* <h1>
                Crafting a New World of Communication <br />
                between Businesses and Customers
            </h1> */}


            <div className="nexgplatforms">
                <div className="nexgcontent">
                    <h2>About Us</h2>
                    Light Equestrian Inc. India was established more than one decade ago,we manufacture & export full range of horse riding products made inbest quality of Leather as well as of Synthetic. Though we made almost every product related to horse riding Like Saddles, Bridles, Halters Ropes, Rugs, Horse Boots, Saddle pads, Harness Sets but our specialty is in making of Treeless Items like Treeless Saddles in Western Styles and English Styles and many design of Saddle pads and bareback pads.
                </div>

                <div className="nexglogo">
                    <img src={horse1} alt="" />
                </div>
            </div>




        </section>

    )
}

export default AboutUs;