import React from 'react'

function SdalePads() {
  return (
    <div>
      SdalePads
    </div>
  )
}

export default SdalePads
