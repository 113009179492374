import React from "react";
import coming from "../Images/Coming.png";
import "./ComingSoon.css";

function ComingSoon() {
    return (
        <div className="comingsoon">
            <img src={coming} alt="" />
        </div>
    )
}

export default ComingSoon;