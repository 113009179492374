import React from "react";
import Dressage from "../../../../Images/DressageSaddles/Syntheticdressage.JPG";
import Jumping from "../../../../Images/JumpingSaddles/Equiroyal synthetic.jpg";
import SaddleCard from "../../../../Templates/SaddleCard";

const data = {
  title: "English Saddles",
  para: "The saddle is a supportive structure for a rider of an animal, fastened to an animal's back by a girth. The most common type is equestrian. However, specialized saddles have been created for oxen, camels and other animals.[1] It is not known precisely when riders first began to use some sort of padding or protection, but a blanket attached by some form of surcingle or girth was probably the first 'saddle', followed later by more elaborate padded designs.",
};

const data1 = [
  {
    title: "Dressage Saddles",
    img: Dressage,
    link: "/saddles/english-saddles/dressage-saddles",
  },
  {
    title: "Jumping Saddles",
    img: Jumping,
    link: "/saddles/english-saddles/jumping-saddles",
  },
];

function EnglishSaddles() {
  return (
    <section className="home-container">
      <div className="home-left">
        <h1>{data.title}</h1>
        <h4>{data.para}</h4>
      </div>
      <div className="saddle-right">
        <h1>Types of English Saddles</h1>
        <div className="saddles_container">
          {data1.map((saddle) => (
            <SaddleCard saddle={saddle} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default EnglishSaddles;
