import React from "react";
import ImageCard from "../../../../Templates/ImageCard";
import WesternData from "../WesternData";

function DressageSaddles() {
  return (
    <section>
      <h1> Jumping Saddles</h1>
      <div className="western_container">
        {WesternData.JumpingSaddles.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default DressageSaddles;
