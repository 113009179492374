import React from 'react'
import ImageCard from "../../../../Templates/ImageCard";
import WesternData from "../WesternData";

function JumpingSaddles() {
  return (
    <section>
        <h1> Dressage Saddles</h1>
        <div className="western_container">
          {WesternData.DressageSaddles.map((img) => (
            <ImageCard img={img} />
          ))}
        </div>
      </section>
  )
}

export default JumpingSaddles
