import React from "react";
import WesternData from "../WesternData";
import ImageCard from "../../../../Templates/ImageCard";
import "./WesternSaddles.css";
function WesternSaddles() {
  return (
    <section>
      <h1> Western Saddles</h1>
      <div className="western_container">
        {WesternData.WesternImages.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default WesternSaddles;
