import React from "react";
import WesternData from "../SubSaddles/WesternData";
import ImageCard from "../../../Templates/ImageCard";

function AllPurposeSaddles() {
  return (
    <div>
      <section>
        <h1> All Purpose Saddles</h1>
        <div className="western_container">
          {WesternData.AllPurposeSaddles.map((img) => (
            <ImageCard img={img} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default AllPurposeSaddles;
