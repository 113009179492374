import React from "react";

function Header({ data }) {
  return (
    <>
      <section className="home-container">
        <div className="home-left">
          <h1>{data.title}</h1>
          <h4>{data.para}</h4>
        </div>
        <div className="home-right">
          <img src={data.img} alt="" />
        </div>
      </section>
    </>
  );
}

export default Header;
