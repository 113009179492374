import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
    return (
        <div className="privacy-container">
            <h1>Privacy Notice</h1>
            <h2>Introduction</h2>
            <p>nexGPAY hereinafter referred as committed to respect your privacy and choices.
                The statement highlights our privacy practices regarding Personal Information that
                we collect and store about you through this website, <a href="https://www.nexgpay.com/">https://www.nexgpay.com/ </a>
                (the “Site”)
            </p>
            <h2>Personal Information that we Collect and Process</h2>
            <p>
                For the purposes of this privacy statement, ‘Personal Information’ is any data which relates to an individual who may be identified from that data,
                or from a combination of a set of data, and other information which is in possession of OneClick. <br />  <br />
                Personal Information or Personally identifiable information we collect is limited to: <br /> <br />
                Email address <br />
                First name and last name <br />
                Phone number <br />
                Address, State, Province, ZIP/Postal code, City <br /> <br />
            </p>
            <h2>Use of Your Personal Information</h2>
            <p>We use the information you provide to:</p>
            <li>To identify you once you register on our website;</li>
            <li> border: 1px solid black;</li>
            <li>Provide the services that you request
                Send you updates and messages that you request.</li>
            <li>To process job application related requests</li>
            <li>Marketing communications – to provide you with marketing communications where you have opted-in to receiving such communications (including information about our products and services, competitions and promotions). These can be shared via electronic means (e.g., emails and online advertising) and via post.</li>
            <br /> <br />

            <h2>Legal Basis of the Processing</h2>
            <p>We process your Personal Information when it is necessary for the performance of a contract to which you are the party or in order to take steps at your request prior to entering into a contract
                <br /> <br />
                We process your Personal Information when it is necessary for the purposes of a legitimate interest pursued by us or a third party (when these interests are not overridden by your data protection rights). This applies in the following circumstances:

                <li>to identify you once you register on our website; and</li>
                <li>to contact you and respond to your questions or requests;</li>
                <li>to protect our rights, privacy, safety, or that of our affiliates, you or others,</li> <br />
                We process your Personal Information when it is necessary as we believe appropriate to comply with applicable law and legal process.
            </p>

            <h2>Consequences of not providing Personal Information</h2>
            <p>If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able to provide the corresponding service</p>

            <h2>Data recipients, transfer, and disclosure of Personal Information</h2>
            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                <br /><br />
                If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there. Your consent to this Privacy Notice followed by your submission of such information represents your agreement to that transfer.
                <br />  <br />
                We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Notice and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
            </p>
            <h2>Third Party Websites/ Linked website</h2>
            <p>We do not provide information to third parties for their own marketing purposes. We may provide personal data to:
                <br /> <br />
                Our website contains links to other sites that are not operated by us such as Twitter, Instagram, Facebook and LinkedIn. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
                <br /> <br />
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.
            </p>
            <h2>Your Rights</h2>
            <p>You have rights in relation to any of your personal data held by us as a data controller. Should you wish to exercise your rights, please contact our Data Protection Officer, via email at dpo [at]oneclickcx.com. We will endeavor to respond to any request promptly and within the legally required time limit.
                <br /> <br />
                Where we process your personal data based on your consent, you have a right to withdraw consent at any time. You have the right to access, correct, delete or transfer your Personal Information that we hold. You also have the right to object to certain processing of your personal information. These rights may be limited in some situations – for example, where we can demonstrate that we have a legal requirement to process your Personal Information.
            </p>
            <h2>Data security</h2>
            <p>
                We have policies, procedures and training in place in respect of data protection, confidentiality and information security. Our information security processes are certified to ISO/IEC 27001:2013 and are regularly reviewed with the objective of ensuring their continuing effectiveness.
                <br /> <br />
                Any complaints or concerns with regards to content or to report any abuse of laws or breach of these terms may be taken up with our Data Protection Officer,
            </p>
            <h2>Data retention</h2>
            <p>Unless there are any overriding legal, regulatory or contractual requirements, we will retain records of services provided (which may include personal data) in accordance with our document retention policy.</p>
            <h2>How to contact us</h2>
             <p>If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us by email: <a href="mailto:nexgpay.com">dpo [at]nexgpay.com</a>  <br /> <br />
             Last updated on: 01 June 2021
             </p>
        </div>
    )
}

export default PrivacyPolicy;