import React from "react";
import WesternData from "../Saddle/SubSaddles/WesternData";
import ImageCard from "../../Templates/ImageCard";

function HarnessSets() {
  return (
    <section>
      <h1>Harness Sets</h1>
      <div className="western_container">
        {WesternData.HarnesSets.map((img) => (
          <ImageCard img={img} />
        ))}
      </div>
    </section>
  );
}

export default HarnessSets;
